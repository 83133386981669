import React, { ReactElement } from "react"
import { Image } from "../../../types/images"
import MoreLink from "../../components/MoreLink"
import WorkDetail from "../../components/WorkDetail"

const imgs: Image[] = [
  {
    src: "Communo/splash-and-login_zcvp6p.jpg",
    alt: "Splash",
    id: "1",
    chrome: false,
  },
  {
    src: "Communo/perks-mockup_fihtbs.jpg",
    alt: "Perks",
    id: "2",
    chrome: false,
  },
  {
    src: "Communo/search-mockup_k27jji.jpg",
    alt: "Search",
    id: "3",
    chrome: false,
  },
]

export default function Communo(): ReactElement {
  return (
    <WorkDetail title="Communo" images={imgs}>
      <p className="lead">
        Communo is a platform that matches highly-vetted talent with agencies
        and brands looking to staff projects or positions.
      </p>

      <p>
        In addition to working with an amazing development team and contributing
        to the apps core functionality, my responsibility also included
        architecting a styling scaffold that allowed the app to be built rapidly
        and efficiently. The styling system is built upon a utility first
        framework. This allowed not only for rapid development but also kept the
        size of the apps CSS files to a minimum by reusing small composible
        styles.
      </p>

      <p>
        One of my other responsibilities was the build pipeline. With the app
        being built by a relatively small dev team we still needed to reach
        users accross multiple different devices and operating systems. From one
        codebase the app is able to be built for Android, iOS and the web. This
        added some complexity to the build step of the app but in the end
        enables the team to have the reach of a much larger team.
      </p>

      <MoreLink
        to="https://apps.apple.com/ca/app/communo/id1349991785"
        label="Download The App"
      />
    </WorkDetail>
  )
}
